import * as actions from "../actions";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { exitChatRoomById, joinChatRoomById, leaveChatRoomById } from "src/services/joined-users.service";
import { createChatRoom, deleteChatRoomById, getAllChatRooms, getChatRoomByCode,  updateChatRoomById } from "../../services/chat-room.service";

interface CurrentChatRoom {
    createdBy: string
    title: string
    uuid: string

}
export interface ChatRoomState {
    currentChatRoom: any
    updateChatRoomCode: string
    scrollBarAtBottom: boolean
}

const initialState: ChatRoomState = {
    currentChatRoom: null,
    updateChatRoomCode: '',
    scrollBarAtBottom: false

}

export interface IChatRoomCreate {
    createdBy: string,
    title: string
    files: any[]
    isPrivate:boolean
    password:string

}

//messages api thunks
export const createChatRoomHandler: any = createAsyncThunk(actions.CHATROOM_CREATE, (data: IChatRoomCreate, { rejectWithValue }) =>
    createChatRoom(data?.createdBy, data?.title, data?.files , data?.isPrivate , data?.password).catch((error) => error && rejectWithValue(error))
);

export const getAllChatRoomsHandler: any = createAsyncThunk(actions.CHATROOM_GET, (_, { rejectWithValue }) =>
    getAllChatRooms().catch((error: any) => error && rejectWithValue(error))
);
export const getChatRoomByCodeHandler: any = createAsyncThunk(actions.CHATROOM_GET_BY_CODE, (code: string, { rejectWithValue }) =>
    getChatRoomByCode(code).catch((error: any) => error && rejectWithValue(error))
);

export const getPrivateChatRoomByCodeHandler: any = createAsyncThunk(actions.CHATROOM_GET_BY_CODE, (code: string, { rejectWithValue }) =>
    getChatRoomByCode(code).catch((error: any) => error && rejectWithValue(error))
);

export const deleteChatRoomByIdHandler: any = createAsyncThunk(actions.DELETE_CHATROOM_BY_ID, (chatroomId: string, { rejectWithValue }) =>
    deleteChatRoomById(chatroomId).catch((error: any) => error && rejectWithValue(error))
);
export const UpdateChatRoomHandler: any = createAsyncThunk(actions.UPDATE_CHATROOM_BY_ID, (data: any, { rejectWithValue }) =>
    updateChatRoomById(data?.code, data?.title, data?.files, data?.isPrivate, data?.password).catch((error: any) => error && rejectWithValue(error))
);

export const leaveChatRoomByIdHandler: any = createAsyncThunk(actions.JOINEDUSER_LEAVE, (data: any, { rejectWithValue }) =>
    leaveChatRoomById(data.userId, data.chatroomId).catch((error: any) => error && rejectWithValue(error))
);

export const exitChatRoomByIdHandler: any = createAsyncThunk(actions.JOINEDUSER_EXIT, (data: any, { rejectWithValue }) =>
    exitChatRoomById(data.userId, data.chatroomId).catch((error: any) => error && rejectWithValue(error))
);

export const joinChatRoomByIdHandler: any = createAsyncThunk(actions.JOIN_CHATROOM, (data: any, { rejectWithValue }) =>
    joinChatRoomById(data?.code,data.userId, data.chatroomId).catch((error: any) => error && rejectWithValue(error))
);


//chatRoomSlice
export const chatRoomSlice = createSlice({
    name: 'chatRoomSlice',
    initialState,
    reducers: {
        removeCurrentChatRoom: (state) => {
            state.currentChatRoom = initialState.currentChatRoom
        },
        UpdateChatRoomByCodeHandler: (state, { payload }: PayloadAction<any>) => {
            state.updateChatRoomCode = payload
        },
        UpdateChatRoom: (state, { payload }: PayloadAction<any>) => {
            state.currentChatRoom = payload
        },
        ScrollBarAtBottomHandler: (state, { payload }: PayloadAction<any>) => {
            state.scrollBarAtBottom = payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createChatRoomHandler.fulfilled, (state, action) => {

        })
        builder.addCase(createChatRoomHandler.rejected, (state, action) => {
        })
        builder.addCase(getAllChatRoomsHandler.fulfilled, (state, action) => {
        })
        builder.addCase(getAllChatRoomsHandler.rejected, (state, action) => {
        })
    builder.addCase(getChatRoomByCodeHandler.fulfilled, (state, action) => {
            state.currentChatRoom = action.payload
        })
        builder.addCase(getChatRoomByCodeHandler.rejected, (state, action) => {
        })
     
        builder.addCase(deleteChatRoomByIdHandler.fulfilled, (state, action) => {
        })
        builder.addCase(deleteChatRoomByIdHandler.rejected, (state, action) => {
        })
        builder.addCase(UpdateChatRoomHandler.fulfilled, (state, action) => {
        })
        builder.addCase(leaveChatRoomByIdHandler.fulfilled, (state, action) => {
            state.currentChatRoom = null
        })
        builder.addCase(leaveChatRoomByIdHandler.rejected, (state, action) => {

        })
        builder.addCase(exitChatRoomByIdHandler.fulfilled, (state, action) => {
            state.currentChatRoom = null
        })
        builder.addCase(exitChatRoomByIdHandler.rejected, (state, action) => {

        })
        builder.addCase(joinChatRoomByIdHandler.fulfilled, (state, action) => {

        })
        builder.addCase(joinChatRoomByIdHandler.rejected, (state, action) => {

        })

    }
})

export const { removeCurrentChatRoom, UpdateChatRoomByCodeHandler, UpdateChatRoom, ScrollBarAtBottomHandler } = chatRoomSlice.actions;
export default chatRoomSlice.reducer;