
// socket emit and listen events
export const MESSAGE_SEND = "message-send"
export const MESSAGE_RES = "message-res"

export const MESSAGE_SHOW_TYPING_SEND = "message-show-typing-send"
export const MESSAGE_SHOW_TYPING_RES = "message-show-typing-res"

export const MESSAGE_HIDE_TYPING_SEND = "message-hide-typing-send"
export const MESSAGE_HIDE_TYPING_RES = "message-hide-typing-res"

export const MESSAGE_RECEIVED_SEND = "message-received-send"
export const MESSAGE_RECEIVED_RES = "message-received-res"

export const CREATE_CHATROOM_SEND = "create-chatroom-send"
export const CREATE_CHATROOM_RES = "create-chatroom-res"

export const DELETE_CHATROOM_SEND = "delete-chatroom-send"
export const DELETE_CHATROOM_RES = "delete-chatroom-res"

export const UPDATE_CHATROOM_SEND = "update-chatroom-send"
export const UPDATE_CHATROOM_RES = "update-chatroom-res"

export const JOIN_CHATROOM_SEND = "join-chatroom-send"
export const JOIN_CHATROOM_RES = "join-chatroom-res"

export const LEAVE_CHATROOM_SEND = "leave-chatroom-send"
export const LEAVE_CHATROOM_RES = "leave-chatroom-res"


//translation languages
export const EN = 'en'
export const DE = 'de'


// Email Regex
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
