import React from 'react'
import ChatRoomList from '../ChatRoom/ChatRoomList'


const Dashboard = () => {
  return (
    <div className='container'>
      <ChatRoomList />
    </div>
  )
}

export default Dashboard