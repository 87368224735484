import * as actions from '../actions';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createMessage, getMessagesByChatRoomId } from "../../services/messages.service";
import { ICreateMessage, Language } from 'src/common/commonTypes';
import { getAllMessagesByChatRoomId } from 'src/services/translate.service';


export interface MessagesState {

}

const initialState: MessagesState = {

}

//messages api thunks
export const sendMessageHandler: any = createAsyncThunk(actions.MESSAGE_CREATE, (data: ICreateMessage, { rejectWithValue }) =>
    createMessage(data).catch((error: any) => error && rejectWithValue(error))
);

export const getMessagesHandler: any = createAsyncThunk(actions.MESSAGE_GET, (data: any, { rejectWithValue }) =>
    getMessagesByChatRoomId(data.chatroomId, data.language, data?.pageSize, data?.perPage, data?.sortColumnProp, data?.sortColumnOrder).catch((error: any) => error && rejectWithValue(error))
);

export const getAllMessagesByChatRoomIdHandler: any = createAsyncThunk(actions.MESSAGE_GET_ALL, (data: any, { rejectWithValue }) =>
    getAllMessagesByChatRoomId(data.chatroomId, data.language, data?.pageSize, data?.perPage, data?.sortColumnProp, data?.sortColumnOrder).catch((error: any) => error && rejectWithValue(error))
);


//messagesSlice
export const messagesSlice = createSlice({
    name: 'messagesSlice',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(sendMessageHandler.fulfilled, (state, action) => {

        })
        builder.addCase(sendMessageHandler.rejected, (state, action) => {

        })
        builder.addCase(getMessagesHandler.fulfilled, (state, action) => {

        })
        builder.addCase(getMessagesHandler.rejected, (state, action) => {

        })
        builder.addCase(getAllMessagesByChatRoomIdHandler.fulfilled, (state, action) => {

        })
        builder.addCase(getAllMessagesByChatRoomIdHandler.rejected, (state, action) => {

        })

    }
})

export const { } = messagesSlice.actions;
export default messagesSlice.reducer;