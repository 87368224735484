import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateChatRoom, getPrivateChatRoomByCodeHandler, joinChatRoomByIdHandler, leaveChatRoomByIdHandler } from 'src/store/slices/chatRoomSlice';
import { RootState } from 'src/store/store';
import { RequestData } from 'src/common/commonTypes';
import * as ACTION from "src/store/actions/index";
import socket from 'src/common/socket';
import { DELETE_CHATROOM_RES, LEAVE_CHATROOM_SEND } from 'src/common/constants';
import { joinedUserActionHandler } from 'src/store/slices/joinedUsersSlice';

const JoinPrivateChatroom = () => {
    let navigate = useNavigate();
    const { t } = useTranslation('common');
    const { currentChatRoom } = useSelector((state: RootState) => state.chatRoom)
    const { user } = useSelector((state: RootState) => state.auth)
    const currentChatRoomId = currentChatRoom?.uuid ?? ''
    const currentChatRoomCode = currentChatRoom?.code ?? ''
    const userId = user?.user?.uuid
    const [chatRoomResponse, setChatRoomResponse] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false);
    let { code } = useParams();
    const dispatch = useDispatch()

    interface initialValues {
        joinPassword: string
    }

    const initialValues: initialValues = {
        joinPassword: ""
    }
    const validationSchema = Yup.object().shape({
        joinPassword: Yup.string()
            .required(t('validation.requiredField')),
    });

    const handleJoinMeeting = async (formValue: initialValues) => {
        const { joinPassword } = formValue;
        setLoading(true)
        if (chatRoomResponse.password === joinPassword) {
            const chatroomId = chatRoomResponse.uuid
            const data: RequestData = { code: code, chatroomId: chatroomId, userId: userId }
            if (currentChatRoom) {
                const currentChatRoomData: RequestData = { code: currentChatRoomCode, chatroomId: currentChatRoomId, userId: userId }
                dispatch(leaveChatRoomByIdHandler(currentChatRoomData)).then((response: any) => {
                    if (response.type === `${ACTION.JOINEDUSER_LEAVE}/${ACTION.FULFILLED}`) {
                        socket.emit(LEAVE_CHATROOM_SEND, currentChatRoomData);
                    }
                })
            }
            dispatch(joinChatRoomByIdHandler(data)).then(async (joinChatRoomResponse: any) => {
                if (joinChatRoomResponse.type === `${ACTION.JOIN_CHATROOM}/${ACTION.FULFILLED}`) {
                    dispatch(joinedUserActionHandler(joinChatRoomResponse.payload.action))
                    dispatch(UpdateChatRoom(chatRoomResponse))
                    setTimeout(() => {
                        navigate('/chatroom/' + code)
                        setLoading(false)
                    }, 1000)
                } else {
                    navigate('/dashboard')
                    toast.error("Not able to join chatroom at the moment. Please try again later.", {
                        position: 'top-right',
                        autoClose: 2500,
                    });
                }
            })
        }
        else {
            toast.error('Incorrect Password', {
                position: 'top-right',
                autoClose: 3000,
            });
            setLoading(false)

        }
    }
    useEffect(() => {
        if (!!code) {
            dispatch(getPrivateChatRoomByCodeHandler(code)).then((result: any) => {
                if (result?.type === `${ACTION.CHATROOM_GET_BY_CODE}/${ACTION.FULFILLED}` && result?.payload) {
                    setChatRoomResponse(result?.payload)
                    if (result?.payload?.createdBy === userId) {
                        dispatch(UpdateChatRoom(result?.payload))
                        navigate('/chatroom/' + code)
                    }
                } else {
                    navigate('/dashboard')
                    toast.error("Chatroom not found!", {
                        position: 'top-right',
                        autoClose: 2500,
                    });
                    console.log("ChatRoom not found2")
                }

            })
        }
        socket.on(DELETE_CHATROOM_RES, async () => {
            navigate("/dashboard")
            toast.error(t('common.ChatRoomDeleted'), {
                position: 'top-right',
                autoClose: 2500,
            });
        });

        return () => {
            socket.off(DELETE_CHATROOM_RES)
        }

    }, [code])

    return (
        <div className='container'>
            <div className="row">
                <div className="col-lg-6">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleJoinMeeting}
                        enableReinitialize={false}
                    >
                        <Form autoComplete="off">
                            <h2 className='fw-bold mb-4'>{'Join chatroom - '}<u>{currentChatRoom?.title}</u></h2>

                            <div className="mb-4">
                                <label htmlFor="user-name" className="form-label">{"User name"}</label>
                                <Field type="text" name="name" className="form-control" id="user-name" value={user?.user?.firstName + ' ' + user?.user?.lastName} readOnly={true} disabled={true}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="meeting-password" className="form-label">{'Password'}</label>
                                <Field type="text" name='joinPassword' className="form-control password-text" id="meeting-password" placeholder={"Enter Password"} />
                                <ErrorMessage name="joinPassword" component="div" className="text-danger txt-error" />
                            </div>
                            <div className="text-end">
                                <button type='submit' className="btn btn-primary mw-120" disabled={loading}>{loading ? t('common.LoadingMsg') : "Join"}</button> 
                                </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div >
    );
}

export default JoinPrivateChatroom
