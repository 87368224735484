import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as actions from '../actions/index';
import { profileupdate } from 'src/services/profile.service';
import { Language } from 'src/common/commonTypes';
import { get } from 'lodash'


//profile initial states
export interface IUserProfile {
    uuid: any
    firstName: string,
    lastName: string,
    language:Language
    files:any[]
}
interface UpdateProfileInitialState {
    isLoading: boolean,
    successful: boolean,
    message: string,
    profilEdit: boolean,
    Profile: any[]
}
const initialState: UpdateProfileInitialState = {
    isLoading: false,
    message: '',
    successful: false,
    profilEdit: false,
    Profile: []
}


//profile api thunks
export const UpdateProfile: any = createAsyncThunk(actions.UPDATEPROFILE, (data: IUserProfile, { rejectWithValue }) =>
    profileupdate(data?.uuid, data?.firstName, data?.lastName , data?.language , data?.files).catch((error) => error && rejectWithValue(error))
);

//profileSlice
export const updateProfileSlice = createSlice({
    name: 'updateProfileSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(UpdateProfile.fulfilled, (state, action) => {
            state.isLoading = false;
            const ResponseMessage = get(action, 'payload.response.data.message');
            if (ResponseMessage) {
                state.message = ResponseMessage;
            }
         
            state.successful = true;
        })
        builder.addCase(UpdateProfile.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, 'payload.response.data.message');
            const networkError = get(error, 'payload.message');
            if(action?.payload?.response?.data?.statusCode === 413){
               state.message = action?.payload?.response?.data?.message
            }else{
            if (errorMessage) {
                state.message = errorMessage;
            } else if (networkError) {
                state.message = networkError;
            }}
            state.isLoading = false;
        })
        builder.addCase(UpdateProfile.pending, (state, action) => {
            state.isLoading = true;

        })

    }
})

export default updateProfileSlice.reducer;
