import { PayloadAction, createSlice } from '@reduxjs/toolkit'

//modal initial states
export interface IModal {
    createChatRoomModal: boolean
}

const initialState: IModal = {
    createChatRoomModal: false,
}

//modalSlice
export const modalSlice = createSlice({
    name: 'modalSlice',
    initialState,
    reducers: {
        createChatRoomModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.createChatRoomModal = payload;
        },
    }
})


export const {createChatRoomModalHandler } = modalSlice.actions;
export default modalSlice.reducer;