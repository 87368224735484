/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as ACTION from "src/store/actions";
import * as Yup from "yup";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { messageHandler, signUpHandler } from '../../store/slices/authSlice';
import ReactSelect from 'react-select';
import { defaultLanguage, languages } from '../../common/languages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { IUser, Language } from 'src/common/commonTypes';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store/store';
import { Spinner } from 'react-bootstrap';
import Logo from 'src/assets/images/logo2.svg'
import { emailRegex } from 'src/common/constants';


const Signup = () => {
  //single hooks starts here
  const { t } = useTranslation('common');
  let navigate = useNavigate();
  const dispatch = useDispatch()


  const { message, signUpIsLoading } = useSelector((state: RootState) => state.auth)


  //useStates starts here
  const [passwordType, setPasswordType] = useState<string>("password");
  const [language, setLanguage] = useState<Language>(defaultLanguage);
  //useStates ends here


  //formik starts here
  const initialValues: IUser = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    language: defaultLanguage
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t('validation.requiredField')).transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .strict(true)
      .test(
        "no-spaces",
        t('trimMessage.trimTitle'),
        (val: any) =>
          val &&
          val.trim() !== ''
      )
      .matches(/^[a-zA-Z\s]+$/, (t('validation.specialcharacterFName')))
      .test(
        "len",
        t('validation.firstnameLimit'),
        (val: any) => {
          const trimmedValue = val && val.toString().trim();
          return trimmedValue && trimmedValue.length >= 3 && trimmedValue.length <= 20;
        }
      ),
    lastName: Yup.string()
      .required(t('validation.requiredField')).transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .matches(/^[a-zA-Z\s]+$/, (t('validation.specialcharacterLName')))
      .strict(true).test(
        "no-spaces",
        t('trimMessage.trimTitle'),
        (val: any) =>
          val &&
          val.trim() !== ''
      )
      .test(
        "len",
        t('validation.lastnameLimit'),
        (val: any) => {
          const trimmedValue = val && val.toString().trim();
          return trimmedValue && trimmedValue.length >= 3 && trimmedValue.length <= 20;
        }
      ),
      email: Yup.string()
      .required(t('validation.requiredField'))
      .transform((value, originalValue) => originalValue.trim())
      .strict(true)
      .matches(emailRegex,(t('validation.notValidEmail')))
      .email(t('validation.notValidEmail'))
      .test("no-spaces", t('trimMessage.trimTitle'), (val:any) => val && val.trim() !== ''),
    password: Yup.string()
      .required(t('validation.requiredField')).transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .strict(true)
      .matches(/^[A-Za-z0-9#@$\s]+$/, (t('validation.specialcharacterPassword')))
      .test(
        "len",
        t('validation.passwordLimitMin'),
        (val: any) => {
          const trimmedValue = val && val.toString().trim();
          return trimmedValue && trimmedValue.length >= 6;
        }

      )
      .test(
        "len",
        t('validation.passwordLimitMax'),
        (val: any) => {
          const trimmedValue = val && val.toString().trim();
          return trimmedValue && trimmedValue.length <= 40;
        }

      ).test(
        "no-spaces",
        t('trimMessage.trimTitle'),
        (val: any) =>
          val &&
          val.trim() !== ''
      ),
  });
  //formik ends here   


  //functions starts here
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const handleRegister = async (initialValues: IUser) => {
    initialValues.language = language
    await dispatch(signUpHandler(initialValues)).then((res: any) => {
      if (res?.type === `${ACTION.SIGNUP}/${ACTION.FULFILLED}`) {
        navigate("/dashboard");
      }
    })
  };

  const handleLanguage = (selectedLanguge: any) => {
    setLanguage(selectedLanguge)
  };
  //functions ends here


  useEffect(() => {
    dispatch(messageHandler())
  }, [])

  return (
    <div className="signup-wrap d-flex flex-column">
      <div className="hstack justify-content-center flex-fill">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-xxl-4 col-xl-6 my-1">
              <div className="card border-0 rounded-4">
                <div className="card-body d-flex flex-column flex-fill justify-content-center px-3 px-sm-5 py-4">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleRegister}
                  >


                    <Form className='vstack gap-4' >
                      <img className='logo' src={Logo} alt="logo" />
                      <h1 className="fs-4 fw-bold text-center mb-4">{t('signUp.title')}</h1>
                      <div>
                        <Field type="text" name="firstName" className="form-control"
                          placeholder={t('signUp.placeholderFirstname')} />
                        <ErrorMessage name="firstName" component="div" className="text-danger txt-error" />
                      </div>
                      <div>
                        <Field name="lastName" className="form-control" type="text"
                          placeholder={t('signUp.placeholderLastname')} />
                        <ErrorMessage name="lastName" component="div" className="text-danger txt-error" />
                      </div>
                      <div>
                        <Field name="email" placeholder={t('signUp.placeholderEmail')}
                          className="form-control" />
                        <ErrorMessage name="email" component="div" className="text-danger txt-error" />
                        {message && (
                          <div className="form-group text-danger txt-error">
                            <div
                              role="alert"
                            >
                              {message}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="password-toggle">
                        <Field type={passwordType} name="password" className="form-control"
                          placeholder={t('common.Password')} />
                        <button type='button' onClick={togglePassword} className="password-toggle-btn btn btn-link p-0 text-black">
                          <FontAwesomeIcon icon={passwordType === 'password' ? faEye : faEyeSlash} />
                        </button>
                        <ErrorMessage name="password" component="div" className="text-danger txt-error" />
                      </div>

                      <div>
                        <ReactSelect
                          onChange={handleLanguage}
                          options={languages}
                          className="react-select-container mw-100 w-100"
                          classNamePrefix="react-select"
                          placeholder="Select language"
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className='hstack justify-content-center'>
                          <Link to="/signin" className="text-decoration-none">{t('signUp.linkLogin')}</Link>
                        </div>
                        <button className="btn btn-primary rounded-3 hstack gap-2" type="submit" disabled={signUpIsLoading}>
                          {signUpIsLoading && <Spinner animation={'border'} size='sm' />}
                          {t('signUp.btnSignUp')}
                        </button>
                      </div>

                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;