import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL


export const profileupdate = async (uuid: any, firstName: string, lastName: string, language: any, files: any[]) => {
    let formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('language', JSON.stringify(language));
    formData.append('photo', files[0]);
    let newHeader = {
        "Content-Type": "multipart/form-data",
    }
    return axios({
        url: API_URL + "users/profile-update/" + uuid,
        method: "post",
        headers: newHeader,
        data: formData
    })
}
export const profileDelete = async (uuid: any) => {
    return axios
        .put(API_URL + "users/profile-delete/"+ uuid,)
        .then((response) => {
            return response.data;
        });
}