import axios from "axios"
import { IRequestMessage, Language } from "src/common/commonTypes"

const API_URL = process.env.REACT_APP_API_URL
export const translateMessage = async (data: IRequestMessage) => {
    try {
        const response = await axios.post(API_URL + "translate", data)
        return response.data
    }
    catch (error) {
        console.error("Error fetching translate data:", error);
        return null
    }
}

export const getAllMessagesByChatRoomId = async (chatroomId: string, language: Language, pageSize: any, perPage: any, sortColumn: any, sortType: any) => {
    const params = { language, page: pageSize, limit: perPage, sortColumn, sortType };
    return axios
        .get(API_URL + "translate/getAllMessages/" + chatroomId, { params })
        .then((response) => {
            return response.data;
        });
};
