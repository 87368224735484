import { faMicrophone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMicVAD, utils } from "@ricky0123/vad-react"
import React, { FC } from "react"
import { Spinner } from "react-bootstrap"
import { AudioFormat } from "src/common/commonTypes"

const APP_VAD_URL = process.env.REACT_APP_VAD_URL


interface VADWithReactProps {
    sendMessageCallback: (blob: AudioFormat) => void
    sendLoading: boolean
}

const VADWithReact: FC<VADWithReactProps> = ({ sendMessageCallback, sendLoading }) => {

    const vad = useMicVAD({
        startOnLoad: false,
        workletURL: `${APP_VAD_URL}vad.worklet.bundle.min.js`,
        modelURL: `${APP_VAD_URL}silero_vad.onnx`,
        ortConfig: (ort) => ort.env.wasm.wasmPaths = APP_VAD_URL,
        onSpeechEnd: (audio) => {
            if (!sendLoading) {
                const wavBuffer = utils.encodeWAV(audio);
                sendMessageCallback(wavBuffer)
            }
        },
    })

    if (vad.loading) {
        return <Loading />
    }

    if (vad.errored) {
        return <Errored />
    }

    const buttonStates = () => {
        if (sendLoading) {
            return
        }

        if (vad.listening) {
            if (vad.userSpeaking) {
                return 'high-energy'
            } else {
                return 'low-energy'
            }
        } else {
            return ''
        }
    }
    return (
        <>
            <div>
                <button className="mic" onClick={vad.toggle} disabled={sendLoading}>
                    {sendLoading && <Spinner className='mic-spinner position-absolute' />}
                    <div className={`pulse-ring ${buttonStates()}`}></div>
                    <FontAwesomeIcon icon={faMicrophone} />
                </button>
            </div>
            <div className="fw-semibold">{vad.listening ? "Listening..." : "Tap to Speak"}</div>
        </>
    )
}
export default VADWithReact;



const Loading = () => {
    return (
        <div className="text-center py-4">
            <div className="fs-5">Loading...</div>
        </div>
    )
}

const Errored = () => {
    return (
        <div className="text-center py-4">
            <div className="fs-5 text-danger">Something went wrong</div>
        </div>
    )
}