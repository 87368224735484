import * as actions from "../actions";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface JoinedUsersState {
    isJoinedUser: boolean
    joinedUserAction: string
}

const initialState: JoinedUsersState = {
    isJoinedUser: false,
    joinedUserAction: ''
}


//joinedUsersSlice
export const joinedUsersSlice = createSlice({
    name: 'joinedUsersSlice',
    initialState,
    reducers: {
        isJoinedUserHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isJoinedUser = payload;
        },
        joinedUserActionHandler: (state, { payload }: PayloadAction<string>) => {
            state.joinedUserAction = payload;
        },
    },
    extraReducers: (builder) => {
    }
})

export const { isJoinedUserHandler, joinedUserActionHandler } = joinedUsersSlice.actions;
export default joinedUsersSlice.reducer;