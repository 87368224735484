import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL

export const joinChatRoomById = async (code:string, userId: string, chatroomId: string) => {
    return axios
        .post(`${API_URL}joined-users/join/`+ code, {
            userId,
            chatroomId
        }).then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error('Error joining chatroom:', error);
            throw error;
        });

};

export const leaveChatRoomById = async (userId: string, chatroomId: string) => {
    return axios
        .post(`${API_URL}joined-users/leave`, {
            userId,
            chatroomId
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error('Error leaving chatroom:', error);
            throw error;
        });
};


export const exitChatRoomById = async (userId: string, chatroomId: string) => {
    return axios
        .delete(`${API_URL}joined-users/delete-joined-user`, {
            params: {
                userId: userId,
                chatroomId: chatroomId,
            }
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error('Error deleting joined user:', error);
            throw error;
        });
};