export const PENDING = "pending";
export const FULFILLED = "fulfilled";
export const REJECTED = "rejected";

/// dispatch auth actions ///
export const SIGNUP = "signUp";
export const LOGIN = "signIn";
export const FORGOTPASSWORD = "forgotPassword";
export const RESETPASSWORD = "resetPassword";

/// dispatch messages actions ///
export const MESSAGE_CREATE = "messageCreate"
export const MESSAGE_GET = "messageGet"
export const MESSAGE_GET_NEW = "messageGetNew"
export const MESSAGE_GET_ALL = 'getAllMessageByChatroom'

/// dispatch chatroom actions ///
export const CHATROOM_CREATE = "chatRoomCreate"
export const CHATROOM_GET = "chatRoomGet"
export const CHATROOM_GET_BY_ID = "chatRoomGetById"
export const CHATROOM_GET_BY_CODE = "chatRoomGetByCode"
export const JOINEDUSER_LEAVE = "joineduserLeave"
export const JOINEDUSER_EXIT = "joineduserExit"
export const JOIN_CHATROOM = "join-chatroom"
export const DELETE_CHATROOM_BY_ID = "deleteChatRoomById"
export const UPDATE_CHATROOM_BY_ID = "updateChatRoomById"

///profile update actions///
export const UPDATEPROFILE = "updateProfile"
