import React, { useEffect, useState, ChangeEvent, useRef, useLayoutEffect } from 'react'
import socket from 'src/common/socket'
import { Language, RequestData, ITranslateMessage, IMessageX, IReceivedMessageX, ICreateMessageX, ErrorMessage, AudioFormat } from 'src/common/commonTypes';
import Typing from 'src/pages/ChatRoom/components/Typing';
import { JOIN_CHATROOM_SEND, MESSAGE_HIDE_TYPING_RES, MESSAGE_HIDE_TYPING_SEND, MESSAGE_RES, MESSAGE_SEND, MESSAGE_SHOW_TYPING_RES, MESSAGE_SHOW_TYPING_SEND, UPDATE_CHATROOM_RES } from 'src/common/constants';
import MessageBubble from './components/MessageBubble';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from 'src/store/store';
import { getAllMessagesByChatRoomIdHandler } from 'src/store/slices/messagesSlice';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dictationModeHandler } from 'src/store/slices/settingsSlice';
import { getChatRoomByCodeHandler, ScrollBarAtBottomHandler, UpdateChatRoom } from 'src/store/slices/chatRoomSlice';
import { toast } from 'react-toastify';
import { MESSAGE_GET_ALL } from 'src/store/actions';
import VADWithReact from './VAD/VADWithReact';
import ShareChatRoomDetails from 'src/components/ShareChatRoom/ShareChatRoomDetails';


const ChatRoom = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let { code } = useParams();
  const { t } = useTranslation('common');

  const { currentChatRoom, scrollBarAtBottom } = useSelector((state: RootState) => state.chatRoom)
  const { user } = useSelector((state: RootState) => state.auth)
  const { isDictationMode } = useSelector((state: RootState) => state.settings)
  const userId = user?.user?.uuid
  const language = user?.user?.language
  const currentChatRoomId = currentChatRoom?.uuid ?? ''
  const currentChatRoomCode = currentChatRoom?.code ?? ''
  const messageListRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesMiddleRef = useRef<HTMLDivElement>(null);
  const reachedEndRef = useRef<boolean>(false);
  const scrollBarAtEnd = useRef<boolean>(false);
  const pageSizeRef = useRef<number>(1)
  //useState starts here
  const [message, setMessage] = useState<string>('')
  const [messageList, setMessageList] = useState<IMessageX[]>([])
  const [typing, setTyping] = useState<boolean>(false)
  const [lines, setLines] = useState<number>(1);
  const [messageLoading, setMessageLoading] = useState<boolean>(false)
  const [sendLoading, setSendLoading] = useState<boolean>(false)
  const maxCharactersLimit = 250

  const [perPage, setperPage] = useState(10);
  const [sortColumn, setsortColumn] = useState<any>({ order: "desc", prop: "createdAt" });
  const [totalItems, setTotalItems] = useState(0);
  const data: RequestData = { code: currentChatRoomCode, chatroomId: currentChatRoomId, userId: userId }
  //useState ends here



  //functions starts here
  const showTyping = () => {
    socket.emit(MESSAGE_SHOW_TYPING_SEND, data)
  }

  const hideTyping = () => {
    if (message.trim() !== '') {
      socket.emit(MESSAGE_HIDE_TYPING_SEND, data)
    }
  }

  const sendMessage = async (blob: AudioFormat = null) => {
    if (currentChatRoomId === '') {
      navigate("/dashboard")
    }

    const trimmedMessage = message.trim()
    if (trimmedMessage === '' && !isDictationMode) {
      return;
    }
    const sendData: ICreateMessageX = {
      userId: userId,
      chatroomId: currentChatRoomId,
      originalMessage: trimmedMessage,
      sendersLanguage: language,
      audio: blob
    }

    if (blob === null) {
      resetMessageBox()
    }
    socket.emit(MESSAGE_SEND, sendData);
    setSendLoading(true)
    scrollBarAtEnd.current = true
    dispatch(ScrollBarAtBottomHandler(true))
  }

  const resetMessageBox = () => {
    setMessage('')
    setLines(1)
  }

  const handleMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
    const newLines: number = e.target.value.split('\n').length;
    setLines(newLines);
    e.target.value.length > 0 ? showTyping() : hideTyping()
  }


  const handleDictationMode = () => {
    setMessage('')
    dispatch(dictationModeHandler(!isDictationMode))
  }

  const getAllMessagesByChatRoomId = async (pageSize: number) => {
    setMessageLoading(true);
    const sortColumnProp = sortColumn.prop;
    const sortColumnOrder = sortColumn.order;
    const getMessageData = {
      chatroomId: currentChatRoomId,
      language: language,
      perPage,
      pageSize: pageSize,
      sortColumnProp,
      sortColumnOrder,
    };
    await dispatch(getAllMessagesByChatRoomIdHandler(getMessageData)).then(async (response: any) => {
      if (response.type === `${MESSAGE_GET_ALL}/fulfilled`) {
        const { data, totalItems } = response.payload;
        // console.log("data", data);

        setMessageList((prevMessages) => [...data, ...prevMessages]);
        setTotalItems(totalItems);
        if (data.length <= 1) {
          reachedEndRef.current = true
        }
      }
      setMessageLoading(false);
      if (pageSizeRef.current === 1) {
        dispatch(ScrollBarAtBottomHandler(true))
      }
      else {
        dispatch(ScrollBarAtBottomHandler(false))
      }
    });
  };

  const scrollToBottom = () => {
    if (scrollBarAtBottom === true) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    } else if (scrollBarAtBottom === false) {
      messagesMiddleRef.current?.scrollIntoView({ behavior: 'auto' });
    }
  };

  const fetchMoreMessagesOnScrollUp = async () => {
    if (!reachedEndRef.current) {
      const newPageSize = pageSizeRef.current + 1;
      pageSizeRef.current = newPageSize
      await getAllMessagesByChatRoomId(newPageSize);
    } else {
      console.log("not found");
    }
  };

  const handleScroll = () => {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      if (scrollTop === 0) {
        fetchMoreMessagesOnScrollUp();
      }
    }
  };
  //functions ends here


  // useEffects starts here
  useEffect(() => {
    const messagesListElement = messageListRef.current;
    if (messagesListElement) {
      messagesListElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messagesListElement) {
        messagesListElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [scrollBarAtBottom, messageList]);

  useEffect(() => {
    if (code === '' || code !== currentChatRoomCode) {
      navigate("/dashboard");
      toast.error("Link is not valid, Please check link!", {
        position: 'top-right',
        autoClose: 2500,
      });
    } else {
      const joinChatroomData = { code: code, chatroomId: currentChatRoomId, userId: userId, user: user }
      socket.emit(JOIN_CHATROOM_SEND, joinChatroomData);
      getAllMessagesByChatRoomId(pageSizeRef.current)
    }

  }, [code])

  useEffect(() => {

    socket.on(MESSAGE_RES, async (socketData: any) => {
      let modifiedData: IReceivedMessageX = socketData.modifiedData
      let error: ErrorMessage = socketData.errorMessage
      const state = store.getState()
      const socketUserId = state.auth.user.user.uuid
      const isReceiver: boolean = modifiedData.userId !== socketUserId

      if (error === null) {
        const getMessageByLanguage = (messages: ITranslateMessage[], languageValue: Language) => {
          if (!messages || !Array.isArray(messages)) {
            return modifiedData.originalMessage;
          }
          for (const message of messages) {
            if (message.language.value === languageValue.value) {
              return message.message;
            }
          }
          return modifiedData.originalMessage;
        }
        const getTranscriptionText = (messages: ITranslateMessage[], languageValue: Language) => {
          if (messages.length === 0) {
            return ''
          }
          if (!messages || !Array.isArray(messages)) {
            return '';
          }
          for (const message of messages) {
            if (message.language.value === languageValue.value) {
              return message.message;
            }
          }
          return '';
        }


        const translatedMessage = getMessageByLanguage(modifiedData.translatedMessages, language)
        const transcriptionText = getTranscriptionText(modifiedData.transcriptionTexts, language)
        const modifiedDataSocket: IMessageX = {
          userId: modifiedData.userId,
          username: modifiedData.username,
          messageId: modifiedData.messageId,
          chatroomId: modifiedData.chatroomId,
          language: modifiedData.language,
          type: modifiedData.type,
          originalMessage: modifiedData.originalMessage,
          translatedMessage: translatedMessage,
          transcriptionText: transcriptionText,
          createdAt: modifiedData.createdAt,
          updatedAt: modifiedData.updatedAt,
        }
        setMessageList([...messageList, modifiedDataSocket])
        setSendLoading(false)
      } else {
        setSendLoading(false)
        if (!isReceiver) {
          toast.error("Audio not sent", {
            position: 'top-right',
            autoClose: 2500,
          });
        }
      }

      if (isReceiver && modifiedData.type === 'text') {
        setTyping(false)
      }
    })

    socket.on(MESSAGE_SHOW_TYPING_RES, async (data: RequestData) => {
      const state = store.getState()
      const socketUserId = state.auth.user.user.uuid
      const isReceiver: boolean = data.userId !== socketUserId
      if (isReceiver) {
        setTyping(true)
      }
    })

    socket.on(MESSAGE_HIDE_TYPING_RES, async (data: RequestData) => {
      const state = store.getState()
      const socketUserId = state.auth.user.user.uuid
      const isReceiver: boolean = data.userId !== socketUserId
      if (isReceiver) {
        setTyping(false)
      }
    })

    socket.on(UPDATE_CHATROOM_RES, async (data: any) => {
      const chatroomCode = data?.code
      await dispatch(getChatRoomByCodeHandler(chatroomCode)).then((result: any) => {
        dispatch(UpdateChatRoom(result?.payload))
      })
    })

    return () => {
      socket.off(MESSAGE_RES)
      socket.off(MESSAGE_SHOW_TYPING_RES)
      socket.off(MESSAGE_HIDE_TYPING_RES)
      // socket.off('join-meeting-res')
    }
  }, [messageList])
  //useEffects ends here

  return (
    <div className='container'>
      <div className="row justify-content-center gy-4">
        <div className="col-xl-4">
          <ShareChatRoomDetails currentChatRoom={currentChatRoom} />
        </div>
        <div className='col-xl-6'>
          <div className="row justify-content-center gy-4">
            <div className="col-12">
              <div className="card ">
                <div className="card-header justify-content-between">
                  <div className='fs-5 fw-medium'>
                    <FontAwesomeIcon icon={faComments} />
                  </div>

                  <div className='hstack gap-4'>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" id="dictation-mode" onChange={handleDictationMode} checked={isDictationMode} />
                      <label className="form-check-label" htmlFor="dictation-mode">{t('common.DictationMode')}</label>
                    </div>
                  </div>
                </div>
                <div ref={messageListRef} className="card-body vstack gap-3 overflow-y-auto message-list">
                  <div ref={messagesMiddleRef}></div>
                  {messageLoading ?
                    <div className='w-100 h-100 place-center flex-column gap-1'>
                      <Spinner />
                      <small>{t('common.TranslatingMessages')}</small>
                    </div>
                    :
                    <>
                      {
                        !!messageList && messageList.length > 0
                          ?
                          <div className='vstack gap-4'>
                            {messageList.map((message: IMessageX, index: number) => {
                              return (
                                <MessageBubble
                                  key={`message-${index}}`}
                                  userId={userId}
                                  message={message}
                                  isDictationMode={isDictationMode}
                                  language={language}
                                />
                              )
                            })}
                            <div ref={messagesEndRef}></div>
                          </div>
                          :
                          <div className='flex-fill place-center'>No Messages</div>
                      }
                      {typing && <Typing />}
                      <div ref={messagesEndRef}></div>
                    </>
                  }
                </div>
                <div className="card-footer">
                  {/* message box */}
                  {
                    !isDictationMode ?
                      <div className='hstack gap-3'>
                        <div className='flex-fill position-relative'>
                          <textarea
                            className={`form-control message-box rounded-${lines > 1 ? '4' : '5'}`}
                            placeholder={t('common.TypeSomething')}
                            value={message}
                            onChange={handleMessage}
                            style={{ height: `${lines * 21}px` }}  //font-size * line-height -> (14 * 1.5 = 21)
                            readOnly={isDictationMode}
                            maxLength={maxCharactersLimit}
                          />
                          <span className='position-absolute bottom-0 end-0 mb-2 me-3 text-muted'>  {(maxCharactersLimit - message.length) + '/' + maxCharactersLimit} </span>
                        </div>


                        {/* clear message input */}

                        {/* send message button */}
                        <button
                          className='btn btn-primary btn-sm rounded-pill size-38 d-flex align-items-center gap-2'
                          title='send message'
                          aria-label='send message'
                          onClick={() => sendMessage()}
                          disabled={sendLoading}
                        >
                          <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                      </div>
                      :
                      <div className='vstack gap-3 align-items-center'>
                        <VADWithReact sendMessageCallback={sendMessage} sendLoading={sendLoading} />
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatRoom