import React, { FC, useEffect, useRef, useState } from "react"
import { Language, IMessageX } from "src/common/commonTypes"
import TranscriptIcon from "src/assets/images/transcript.svg"
import HideTranscriptIcon from "src/assets/images/hideTranscript.svg"
import DefaultProfileImg from "src/assets/images/DefaultProfileImg.svg"

interface MessageBubbleProps {
    userId: string
    message: IMessageX
    isDictationMode: boolean
    language: Language,
}

const MessageBubble: FC<MessageBubbleProps> = React.memo(({ userId, message, language }) => {
    const transcription = message.transcriptionText
    const isTranscription = !!transcription && transcription !== ''
    const [showTranscription, setShowTranscription] = useState<boolean>(false)
    const [isOriginal, setIsOriginal] = useState<boolean>(false)
    const isSender: boolean = message.userId === userId
    const isSameLanguage: boolean = message.language.value === language.value
    const audioMessage = isSender && isSameLanguage ? message.originalMessage : message.translatedMessage
    const textMessage = isSender && isSameLanguage ? message.originalMessage : isOriginal ? message.originalMessage : message.translatedMessage
    const username = message.username.firstName + ' ' + message.username.lastName
    const userImg = message.username?.profileImage ?? DefaultProfileImg


    return (
        <div className={`hstack align-items-start gap-2 ${isSender ? 'sender flex-row-reverse' : 'receiver'}`}>
            {/* <OverlayTrigger overlay={<Tooltip id={message.messageId}>{username}</Tooltip>}> */}
            <div className="bubble-profile-image" data-bs-toggle="title" title={username}><img src={userImg} className="w-100 h-100 object-fit-cover" alt={username} /></div>
            {/* </OverlayTrigger> */}
            {message.type === "audio" ?
                <div>
                    <div className={`hstack gap-2 ${isSender ? 'flex-row-reverse' : ''}`}>
                        <AudioPlayer url={audioMessage} />
                        {isTranscription &&
                            <button onClick={() => setShowTranscription(!showTranscription)} className='btn btn-link p-0' style={{ height: 26 }}>
                                <img src={showTranscription ? HideTranscriptIcon : TranscriptIcon} height={showTranscription ? 26 : 20} alt="" />
                            </button>
                        }
                    </div>
                    {isTranscription && showTranscription && <div><div className="bubble rounded-top-0 mt-1">{transcription}</div></div>}
                </div>
                :
                <>
                    <pre className='bubble position-relative'>
                        {textMessage}
                    </pre>
                    {!isSender && !isSameLanguage &&
                        <button onClick={() => setIsOriginal(!isOriginal)} className='btn btn-link p-0' style={{ height: 26, marginTop: '2px' }}>
                            <img src={isOriginal ? HideTranscriptIcon : TranscriptIcon} height={isOriginal ? 26 : 20} alt="" />
                        </button>
                    }
                </>
            }

        </div>
    )
})
export default MessageBubble

interface AudioPlayerProps {
    url: string
    classes?: string
}
const AudioPlayer: FC<AudioPlayerProps> = ({ url, classes = '' }) => {
    const audioRef = useRef<HTMLAudioElement>(null)

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.src = url
            audioRef.current.load()

            //optionally play audio automatically
            // audioRef.current.play()
        }
    }, [])
    return (
        <audio ref={audioRef} controls className={`audio-message ${classes}`} />
    )

}