import * as actions from "../actions";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { forgotPassword, login, register, resetPassword } from "../../services/auth.service";
import { Language } from "src/common/commonTypes";
import { get } from 'lodash'
import { t } from "i18next";

export interface AuthState {
    user: any,
    isUser: boolean,
    message: string
    signInMessage: string
    signInIsLoading: boolean
    signInSuccesfull: boolean,
    signUpMessage: string
    signInRememberMe: boolean
    signInRememberName: string
    forgotPasswordMessage: string,
    forgotPasswordIsLoading: boolean
    forgotPasswordSuccesfull: boolean
    signUpIsLoading: boolean,
    successful: boolean


}

const initialState: AuthState = {
    user: {},
    isUser: false,
    message: '',
    signInMessage: '',
    signUpMessage: '',
    signInIsLoading: false,
    signInSuccesfull: false,
    signInRememberMe: false,
    signInRememberName: '',
    forgotPasswordMessage: '',
    forgotPasswordIsLoading: false,
    forgotPasswordSuccesfull: false,
    signUpIsLoading: false,
    successful: false,
}

export interface IUser {
    id?: any | null,
    firstName: string,
    lastName: string,
    email: string,
    password: string
    language: Language
}
export interface IUserLogin {
    email: string;
    password: string;
}

//auth api thunks
export const signUpHandler: any = createAsyncThunk(actions.SIGNUP, (data: IUser, { rejectWithValue }) =>
    register(data?.firstName.trim(), data?.lastName.trim(), data?.email.trim(), data?.password.trim(), data?.language).catch((error) => error && rejectWithValue(error))
);

export const signInHandler: any = createAsyncThunk(actions.LOGIN, (data: IUserLogin, { rejectWithValue }) =>
    login(data?.email.trim(), data?.password.trim()).catch((error) => error && rejectWithValue(error))
);
export const forgotPasswordHandler: any = createAsyncThunk(actions.FORGOTPASSWORD, (data: any, { rejectWithValue }) =>
    forgotPassword(data?.email.trim()).catch((error) => error && rejectWithValue(error))
);
export const resetPasswordHandler: any = createAsyncThunk(actions.RESETPASSWORD, (data: any, { rejectWithValue }) =>
    resetPassword(data?.token, data?.password).catch((error) => error && rejectWithValue(error))
);

//authSlice
export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        userHandler: (state, { payload }: PayloadAction<any>) => {
            state.user = payload;
            state.isUser = true;
        },
        logoutHandler: (state) => {
            state.user = {};
            state.isUser = false;
            sessionStorage.clear();
        },
        signInMessageHandler: (state) => {
            state.signInMessage = '';
        },
        signInRememberMeHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.signInRememberMe = payload
        },
        signInRememberNameHandler: (state, { payload }: PayloadAction<string>) => {
            state.signInRememberName = payload
        },
        messageHandler: (state) => {
            state.message = '';
        },

    },
    extraReducers: (builder) => {
        builder.addCase(signUpHandler.fulfilled, (state, action) => {
            state.user = action.payload;
            state.isUser = true;
            state.signUpIsLoading = false;
            state.successful = true;
        })
        builder.addCase(signUpHandler.pending, (state, action) => {
            state.signUpIsLoading = true;
        })
        builder.addCase(signUpHandler.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, 'payload.response.data.message');
            const networkError = get(error, 'payload.message');
            if (errorMessage) {
                state.message = errorMessage;
            } else if (networkError) {
                state.message = networkError;
            }
            state.signUpIsLoading = false;

        })
        builder.addCase(signInHandler.fulfilled, (state, action) => {
            state.user = action.payload;
            state.isUser = true;
            state.signInIsLoading = false;
            state.signInSuccesfull = true;
            state.signInMessage = (t('validation.successfullyLoginmsg'));
        })
        builder.addCase(signInHandler.pending, (state, action) => {
            state.signInMessage = '';
            state.signInIsLoading = true;
        })
        builder.addCase(signInHandler.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, 'payload.response.data.message');
            const networkError = get(error, 'payload.message');
            if (errorMessage) {
                state.signInMessage = errorMessage;
            } else if (networkError) {
                state.signInMessage = networkError;
            }
            state.signInSuccesfull = false;
            state.signInIsLoading = false;
        })
        builder.addCase(forgotPasswordHandler.pending, (state, action) => {
            state.forgotPasswordMessage = '';
            state.forgotPasswordIsLoading = true;
        })
        builder.addCase(forgotPasswordHandler.fulfilled, (state, action) => {
            state.forgotPasswordIsLoading = false;
            state.forgotPasswordSuccesfull = true;
        })
        builder.addCase(forgotPasswordHandler.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, 'payload.response.data.message');
            const networkError = get(error, 'payload.message');
            if (errorMessage) {
                state.forgotPasswordMessage = errorMessage;
            } else if (networkError) {
                state.forgotPasswordMessage = networkError;
            }
            state.forgotPasswordSuccesfull = false;
            state.forgotPasswordIsLoading = false;
        })

    }
})

export const { logoutHandler, userHandler, signInMessageHandler, signInRememberNameHandler, signInRememberMeHandler,messageHandler } = authSlice.actions;
export default authSlice.reducer;