import React, { FC } from 'react'
import { ChatRoom } from 'src/common/commonTypes'
import ChatRoomCard from './components/ChatRoomCard'

interface ChatRoomCardWrapperProps {
    chatrooms: ChatRoom[]
    myChatRoom?: boolean
}
const ChatRoomCardWrapper: FC<ChatRoomCardWrapperProps> = ({ chatrooms, myChatRoom }) => {
    return (
        <div className='row g-4'>
            {!!chatrooms && chatrooms.length > 0 &&
                chatrooms?.map((chatroom: ChatRoom, index: number) => {
                    return (
                        <div className="col-sm-6 col-lg-4 col-xl-3 col-xxl-2" key={index}>
                            <ChatRoomCard chatroomData={chatroom} myChatRoom={myChatRoom}/>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ChatRoomCardWrapper